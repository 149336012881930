exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-career-index-tsx": () => import("./../../../src/pages/career/index.tsx" /* webpackChunkName: "component---src-pages-career-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-datascience-form-index-tsx": () => import("./../../../src/pages/datascience-form/index.tsx" /* webpackChunkName: "component---src-pages-datascience-form-index-tsx" */),
  "component---src-pages-datascience-index-tsx": () => import("./../../../src/pages/datascience/index.tsx" /* webpackChunkName: "component---src-pages-datascience-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-mern-stack-index-tsx": () => import("./../../../src/pages/mern-stack/index.tsx" /* webpackChunkName: "component---src-pages-mern-stack-index-tsx" */),
  "component---src-pages-mernstack-form-index-tsx": () => import("./../../../src/pages/mernstack-form/index.tsx" /* webpackChunkName: "component---src-pages-mernstack-form-index-tsx" */),
  "component---src-pages-our-works-index-tsx": () => import("./../../../src/pages/our-works/index.tsx" /* webpackChunkName: "component---src-pages-our-works-index-tsx" */),
  "component---src-pages-php-laravel-form-index-tsx": () => import("./../../../src/pages/php-laravel-form/index.tsx" /* webpackChunkName: "component---src-pages-php-laravel-form-index-tsx" */),
  "component---src-pages-php-laravel-index-js": () => import("./../../../src/pages/php-laravel/index.js" /* webpackChunkName: "component---src-pages-php-laravel-index-js" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-projectmanager-form-index-tsx": () => import("./../../../src/pages/projectmanager-form/index.tsx" /* webpackChunkName: "component---src-pages-projectmanager-form-index-tsx" */),
  "component---src-pages-projectmanager-index-tsx": () => import("./../../../src/pages/projectmanager/index.tsx" /* webpackChunkName: "component---src-pages-projectmanager-index-tsx" */),
  "component---src-pages-react-developer-form-index-tsx": () => import("./../../../src/pages/react-developer-form/index.tsx" /* webpackChunkName: "component---src-pages-react-developer-form-index-tsx" */),
  "component---src-pages-react-developer-index-tsx": () => import("./../../../src/pages/react-developer/index.tsx" /* webpackChunkName: "component---src-pages-react-developer-index-tsx" */),
  "component---src-pages-react-firebase-developer-form-index-tsx": () => import("./../../../src/pages/react-firebase-developer-form/index.tsx" /* webpackChunkName: "component---src-pages-react-firebase-developer-form-index-tsx" */),
  "component---src-pages-react-firebase-developer-index-tsx": () => import("./../../../src/pages/react-firebase-developer/index.tsx" /* webpackChunkName: "component---src-pages-react-firebase-developer-index-tsx" */),
  "component---src-pages-react-native-form-index-tsx": () => import("./../../../src/pages/react-native-form/index.tsx" /* webpackChunkName: "component---src-pages-react-native-form-index-tsx" */),
  "component---src-pages-react-native-index-tsx": () => import("./../../../src/pages/react-native/index.tsx" /* webpackChunkName: "component---src-pages-react-native-index-tsx" */),
  "component---src-pages-services-index-tsx": () => import("./../../../src/pages/services/index.tsx" /* webpackChunkName: "component---src-pages-services-index-tsx" */),
  "component---src-pages-team-index-tsx": () => import("./../../../src/pages/team/index.tsx" /* webpackChunkName: "component---src-pages-team-index-tsx" */),
  "component---src-pages-teammanager-form-index-tsx": () => import("./../../../src/pages/teammanager-form/index.tsx" /* webpackChunkName: "component---src-pages-teammanager-form-index-tsx" */),
  "component---src-pages-teammanager-index-tsx": () => import("./../../../src/pages/teammanager/index.tsx" /* webpackChunkName: "component---src-pages-teammanager-index-tsx" */),
  "component---src-pages-testimonials-index-tsx": () => import("./../../../src/pages/testimonials/index.tsx" /* webpackChunkName: "component---src-pages-testimonials-index-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-uiux-form-index-tsx": () => import("./../../../src/pages/uiux-form/index.tsx" /* webpackChunkName: "component---src-pages-uiux-form-index-tsx" */),
  "component---src-pages-uiux-index-tsx": () => import("./../../../src/pages/uiux/index.tsx" /* webpackChunkName: "component---src-pages-uiux-index-tsx" */),
  "component---src-templates-service-tsx": () => import("./../../../src/templates/service.tsx" /* webpackChunkName: "component---src-templates-service-tsx" */),
  "component---src-templates-team-tsx": () => import("./../../../src/templates/team.tsx" /* webpackChunkName: "component---src-templates-team-tsx" */),
  "component---src-templates-testimonial-tsx": () => import("./../../../src/templates/testimonial.tsx" /* webpackChunkName: "component---src-templates-testimonial-tsx" */)
}


module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-XXX-1","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","ha","ig","yo"],"defaultLanguage":"en","siteUrl":"https://www.forkhive.com","trailingSlash":"always","i18nextOptions":{"fallbackLng":"en","supportedLngs":["en","ha","ig","yo"],"defaultNS":"common","keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/","languages":["en","ha","ig","yo"]},{"matchPath":"/pricing","languages":["en","ha","ig","yo"]},{"matchPath":"/our-works","languages":["en","ha","ig","yo"]}]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
